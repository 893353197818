import React from 'react'
import Layout from '../components/Layout'
import Hero from '../components/Hero'
import { Link } from 'gatsby'
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    
    <SEO title="404 page not found"
       
    />
        
    <Hero 
         headerText="Page not found" 
         subHeaderText="This is a 404 error page as the content wasn't found"
            /> 

    <section className="section column is-10 is-offset-1 content-pages">
      <div className="container">
        <div className="content center intro">
            <h2>The page you were looking for wasn't found</h2>
            
            <p>For some reason the page you were looking for wasn't found. Might have been a bad link or an old page that doesn't exist any more. .</p>

            <p className="" style={{marginTop: 30, marginBottom: 50}}><Link to="/contact/" className="btn">Contact us if you have questions</Link></p>

        </div>

      </div>
    </section>

  </Layout>
)

export default NotFoundPage
